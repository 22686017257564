@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

::selection{
  color: #667EFF;
  background-color: #eee;
}

img{
  pointer-events: none;
}

body {
  margin: 0;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #222;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -moz-osx-font-smoothing: grayscale;
}

#root{
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}

a{
  text-decoration: none;
  color: #222;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
