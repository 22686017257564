@mixin transition{
    -o-transition: all 100ms cubic-bezier(.165, .84, .44, 1);
    -moz-transition: all 100ms cubic-bezier(.165, .84, .44, 1);
    -webkit-transition: all 100ms cubic-bezier(.165, .84, .44, 1);
    transition: all 100ms cubic-bezier(.165, .84, .44, 1);
}

.work{
    ul{
        visibility: visible;
        list-style-type: none;
    }
    .container{
        width: 100%;
        display: flex;
        flex-direction: column;
        &::before{
            content: "";
            background: url("../../img/brand-logo.svg") 00 no-repeat;
            background-repeat: no-repeat;
            transform: rotate(-45deg);
            width: 600px;
            height: 338px;
            position: absolute;
            z-index: -1;
            opacity: 1;
            top: 0;
            right: 0;
            padding: 50px;
        }
        .filter{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid #eee;
            padding: 5px 50px;
            span{
                color: #555;
                font-family: inherit;
                font-size: 14px;
                padding: 10px;
            }
            button{
                font-family: inherit;
                font-size: 14px;
                padding: 10px;
                margin: 0px 3px;
                border: 1px solid transparent;
                border-radius: 5px;
                background-color: transparent;
                cursor: pointer;
                @include transition;
                &:hover{
                    background-color: #f2f2f3;
                }
                &:focus{
                    color: #fff;
                    background-color: #667EFF;
                    box-shadow: 0px 0px 0px 2px #b7c2ff;
                }
            }
        }
    }
    .work-box-area{
        display: flex;
        flex-direction: row;
        max-width: 100%;
        height: 100%;
        padding: 20px;
        overflow: auto;
        background-color: #fcfcfc;
        scrollbar-width: thin;
        &::-webkit-scrollbar{
            background-color: #fcfcfc;
            width: 10px;
            height: 10px;
        }
        &::-webkit-scrollbar-track{
            background-color: transparent;
        }
        &:hover > ::-webkit-scrollbar-thumb {
            background-color: #a0a0a5;
          }
        &::-webkit-scrollbar-thumb{
            background-color: #ccc;
            border-radius:16px;
            border: 2px solid #fcfcfc;
        }
        &::-webkit-scrollbar-thumb:hover {
            background-color:#a0a0a5;
        }
        &::-webkit-scrollbar-button{
            display:none;
        }
        a{
            &:hover > .work-box .work-title h3{
                color: #222;
            }
            &:hover > .work-box button{
                border: 1px solid #667EFF;
                background-color: #fff;
                color: #222;
                transform: rotate(0deg);
            }
            &.active > .work-box button{
                border: 1px solid transparent;
                background-color: #667EFF;
                color: #fff;
                transform: rotate(90deg);
            }
            &.active > .work-box{
                opacity: 1;
                filter: none;
                background-color: #fff;
                border: 1px solid #222;
                transform: scale(1.007, 1.007);
                box-shadow: 0 0 0 3px #667EFF;
            }
            &.active > .work-box .work-title h3{
                color: #222;
                font-weight: 600;
            }
            &.active .page{
                border-top: 1px solid #667EFF;
            }
        }
        .work-box{
            margin: 0px 5px;
            padding: 5px 7px 5px 5px;
            width: 180px;
            background-color: #f9f9f9;
            border-radius: 5px;
            border: 1px solid #ccc;
            opacity: .7;
            filter: grayscale(1);
            @include transition;
            &:hover{
                border: 1px solid #aaa;
                opacity: 1;
                filter: none;
                background-color: #fff;
            }
            .work-title{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                h3{
                    color: #999;
                    margin: 5px;
                    font-weight: 500;
                    font-size: 14px;
                }
                button{
                    color: #999;
                    font-family: inherit;
                    font-size: 10px;
                    margin-top: 4px;
                    padding: 1px;
                    width: 24px;
                    height: 24px;
                    border: 1px solid transparent;
                    border-radius: 50px;
                    background-color: #ddd;
                    cursor: inherit;
                    transform: rotate(-45deg);
                    @include transition;
                }
            }
            .image{
                width: 180px;
                height: 100px;
                border-radius: 5px;
                border: 1px solid #ddd;
            }
        }
    }
    .page{
        display: flex;
        flex-flow: column wrap;
        background-color: #fff;
        border-top: 1px solid #222;
        padding: 0px 60px 10px;
        overflow-x: hidden;
        .work-detail{
            .default{
                max-width: 1012px;
            }
            article{
                b{
                    font-weight: 700;
                    color: #667EFF;
                }
                em{
                    font-style: italic;
                    color: #667EFF;
                }
                h1{
                    font-weight: 500;
                    font-size: 36px;
                    line-height: 44px;
                    letter-spacing: -1px;
                }
                .content{
                    h2{
                        width: 18%;
                        font-weight: 500;
                        font-size: 24px;
                        color: #667EFF;
                    }
                    .header{
                        display: flex;
                        flex-flow: column wrap;
                        justify-content: center;
                        .cover{
                            display: flex;
                            justify-content: center;
                            padding: 10px;
                            border: 1px solid #222;
                            border-radius: 5px 5px 0px 0px;
                            img{
                                width: 100%;
                                max-width: 500px;
                                height: 100%;
                                max-height: 280px;
                            }
                        }
                        .properties{
                            display: flex;
                            flex-flow: row;
                            padding: 10px 10px;
                            background-color: #fcfcfc;
                            border: 1px solid #222;
                            border-top: none;
                            border-radius: 0px 0px 5px 5px;
                            div{
                                padding: 0px 15px;
                                &.credits{
                                    padding-left: 0px;
                                }
                                &:first-of-type{
                                    padding-left: 0px;
                                    border-right: 1px solid #ddd;
                                    border-radius: 5px 0px 0px 5px;
                                }
                                &:last-of-type{
                                    border-left: 1px solid #ddd;
                                    border-radius: 0px 5px 5px 0px;
                                }
                                span{
                                    font-size: 12px;
                                    margin: 0px;
                                    padding: 0px;
                                    color: #999;
                                    text-transform: uppercase;
                                }
                                p{
                                    font-size: 15px;
                                    margin: 0px;
                                    color: #222;
                                }
                            }
                        }
                    }
                    .post{
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: space-between;
                        padding: 20px 0px 0px 0px;
                        p{
                            width: 80%;
                            text-align: justify;
                            font-size: 17px;
                            line-height: auto;
                            font-weight: 300;
                            white-space: pre-line;
                            vertical-align: bottom;
                            color: #333;
                            .journal{
                                h1{
                                    line-height: inherit;
                                }
                                h2{
                                    width: initial;
                                    font-size: 20px;
                                    color: #626f79;
                                }
                                h3{
                                    margin: 0px;
                                    padding: 2px 10px;
                                    font-size: 12px;
                                    font-weight: 300;
                                    color: #999;
                                    border-radius: 5px;
                                    background-color: #f4f4f4;
                                    text-transform: uppercase;
                                }
                                h5{
                                    text-align: center;
                                    font-size: small;
                                    font-weight: 300;
                                    color: #aaa;
                                    padding: 0;
                                }
                                hr{
                                    border: none;
                                    border-top: 1px solid #eee;
                                }
                                ul{
                                    li{
                                        &:before{
                                            content: "\2022";
                                            color: #626f79;
                                            display: inline-block;
                                            width: 1em;
                                            margin-left: -1em;
                                        }
                                    }
                                }
                                p{
                                    width: 100%;
                                    em{
                                        color: inherit;
                                    }
                                    strong{
                                        font-weight: 500;
                                    }
                                    img{
                                        cursor:sw-resize;
                                        pointer-events: unset;
                                        border-radius: 5px;
                                        max-width: 100%;
                                        width: 100%;
                                        transition: ease-in-out 0.2s;
                                        &:hover{
                                            transform:scale(1.03);
                                        }
                                    }
                                }
                            }
                            div{
                                margin-top: 20px;
                                a{
                                    border-radius: 5px;
                                    color: #8899a6;
                                    &:hover{
                                        color: #505b63;
                                    }
                                }
                            }
                        }
                    }
                    .media{
                        border-top: 1px solid #eee;
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: space-between;
                        padding: 10px 0px;
                        .misc{
                            width: 80%;
                            .tabbed{
                                overflow-x: hidden;
                                [type="radio"]{
                                    display: none;
                                }
                            }
                            .tabs{
                                margin: 10px 0px;
                                display: flex;
                                flex-flow: row;
                                padding: 0px;
                                justify-content: flex-start;
                                list-style: none;
                            }
                            .tab{
                                > label {
                                display: block;
                                padding: 10px 15px;
                                border-radius: 5px;
                                background: #fff;
                                color: #8899a6;
                                font-size: 12px; 
                                font-weight: 500;
                                text-transform: uppercase;
                                letter-spacing: 1px;
                                cursor: pointer;	
                                transition: all 0.3s;
                                &:hover{
                                    color: #626f79;
                                }
                                }
                            }
                            .tab-content {
                                display: none;
                                color: #777;
                                margin: 0px -5px;
                                padding: 0px 5px;
                                img{
                                    max-width: 100%;
                                    border-radius: 5px;
                                }
                                iframe{
                                    width: calc(100% - 2px);
                                    height: 800px;
                                    border: 1px solid #222;
                                    border-radius: 5px;
                                }
                            }
                            .tabbed [type="radio"]:nth-of-type(1):checked ~ .tabs .tab:nth-of-type(1) label,
                            .tabbed [type="radio"]:nth-of-type(2):checked ~ .tabs .tab:nth-of-type(2) label {
                                background-color: #667EFF;
                                color: #fff;
                            }
                            .tabbed [type="radio"]:nth-of-type(1):checked ~ .tab-content:nth-of-type(1),
                            .tabbed [type="radio"]:nth-of-type(2):checked ~ .tab-content:nth-of-type(2){
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .work{
        .container{
            width: 100%;
            padding: 0px;
            &::before{
                opacity: .3;
                // display: none;
            }
            .filter{
                width: 100%;
                padding: 5px 20px;
                span{
                    padding: 0px 5px 0px 0px;
                }
                .tag{
                    padding: 5px;
                }
            }
            .work-box-area{
                padding: 15px 5px;
            }
            .page{
                padding: 0px 20px 20px 20px;
                .work-detail{
                    .default{
                        max-width: 100%;
                        h1{
                            font-size: 24px;
                            letter-spacing: -.5px;
                            line-height: 36px;
                        }
                    }
                    article{
                        .content{
                            .properties{
                                flex-flow: column;
                                div{
                                    padding: 0px;
                                    &:first-of-type{
                                        border-right: none;
                                    }
                                    &:last-of-type{
                                        border-left: none;
                                    }
                                    span{
                                        font-size: 11px;
                                    }
                                    p{
                                        font-size: 14px;
                                        letter-spacing: -1px;
                                    }
                                }
                            }
                        }
                        .post{
                            display: flex;
                            flex-flow: column;
                            padding: 10px 0px;
                            h2{
                                width: 100%;
                            }
                            p{
                                margin-top: 0px;
                                width: 100%;
                                font-size: 15px;
                            }
                        }
                        .media{
                            display: flex;
                            flex-flow: column;
                            padding: 10px 0px;
                            .misc{
                                .tabs{
                                    margin: 10px 0px 10px;
                                    display: flex;
                                    flex-flow: row;
                                    justify-content: space-between;
                                    list-style: none;
                                    .tab{
                                        width: 50%;
                                        text-align: center;
                                        > label{
                                            padding: 5px;
                                            font-size: 10px;
                                        }
                                    }
                                }
                            }
                            h2{
                                width: 100%;
                            }
                            .misc{
                                width: 100%;
                            }
                        }
                        h1{
                            letter-spacing: -.5px;
                        }
                        h3{
                            font-size: 17px;
                            letter-spacing: -.5px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
    .work{
        .container{
            width: 100%;
            padding: 0px;
            &::before{
                opacity: .3;
                // display: none;
            }
            .page{
                padding: 0px 40px 10px;
                .work-detail{
                    .default{
                        max-width: 100%;
                        h1{
                            margin: 30px 0px 25px;
                            font-size: 36px;
                            letter-spacing: -.3px;
                            line-height: 48px;
                        }
                    }
                    article{
                        h1{
                            letter-spacing: -.3px;
                        }
                        h3{
                            font-size: 19px;
                            letter-spacing: -.3px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }
}